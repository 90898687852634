/* src/components/OptOutBanner/styles.css */
.opt-out-banner {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #333;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #fff;
}

.opt-out-banner p {
  margin: 0;
}

.opt-out-banner button {
  background-color: transparent;
  border: none;
  color: #007bff;
  cursor: pointer;
}

.dismiss-button {
  color: #999;
  font-size: 18px;
  cursor: pointer;
  margin-right: auto;
}
