/* src/features/helldivers2/components/Generator/styles.css */
body {
  font-family: Arial, sans-serif;
  background-color: #333;
  color: #fff;
  margin: 0;
  padding: 20px;
}

.container {
  max-width: 600px;
  margin: 0 auto;
  background-color: #222;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  position: relative;
}

h1 {
  text-align: center;
  color: #fff;
}

.button {
  display: block;
  width: 100%;
  padding: 10px;
  font-size: 16px;
  text-align: center;
  background-color: #ffe80a;
  color: black;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-bottom: 20px;
}

.loadout {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 20px;
}

.loadout-item {
  background-color: #444;
  padding: 10px;
  border-radius: 4px;
  text-align: center;
}

.loadout-item h3 {
  margin-top: 0;
  color: #fff;
}

.loadout-item p {
  margin-bottom: 0;
}

.loadout-item img {
  max-width: 25%;
  border-radius: 4px;
}
